import * as React from "react"
import { graphql, HeadProps, PageProps } from "gatsby"
import { paragraphResolver } from "../helpers/paragraphsResolver"
import RelatedCaseStudies from "../components/related-case-studies/related-case-studies"
import Layout from "../components/layout/layout"
import Hero from "../components/hero/hero"
import Seo from "../components/seo/seo"
import {
  DrupalNode,
  DrupalFormattedText,
  DrupalParagraph,
  DrupalMediaImage,
  DrupalTaxonomyTerm,
  DrupalLink,
} from "../types/drupal"

export interface NodeCaseStudyProps extends DrupalNode {
  field_hero_text?: DrupalFormattedText
  field_headline: string
  field_eyebrow?: string
  field_plain_text?: string
  field_year?: number
  field_link?: DrupalLink
  relationships: {
    field_components?: DrupalParagraph[]
    field_media?: DrupalMediaImage
    field_scope_of_work?: DrupalTaxonomyTerm[]
    field_related_case_studies?: NodeCaseStudyProps[]
    field_categories?: DrupalTaxonomyTerm[]
  }
}

interface DataProps {
  nodeCaseStudy: NodeCaseStudyProps
}
const CaseStudyTemplate = ({
  data: { nodeCaseStudy },
}: PageProps<DataProps>) => {
  const {
    field_hero_text,
    field_headline,
    relationships,
    field_eyebrow,
    field_year,
    field_plain_text,
    field_link,
  } = nodeCaseStudy

  // Pass the block field to the block resolver, which returns an array of block components.
  const components = relationships?.field_components
  const media = relationships?.field_media
  const heroText = field_hero_text?.value
  const introHeading = field_eyebrow
  const year = field_year
  const duration = field_plain_text
  const ctaLink = field_link?.url
  const cases = relationships?.field_related_case_studies?.map(caseStudy => ({
    link: caseStudy.path?.alias,
    title: caseStudy.field_headline,
    year: caseStudy.field_year,
    categories: caseStudy.relationships?.field_categories,
  }))

  return (
    <Layout isCaseStudy={true}>
      <Hero
        variant="caseStudy"
        title={field_headline}
        introHeading={introHeading}
        introText={heroText}
        media={media}
        level={1}
        year={year}
        scopeOfWork={relationships?.field_scope_of_work}
        duration={duration}
        link={ctaLink}
      />
      {components &&
        components.map(component => {
          return (
            <React.Fragment key={component.id}>
              {paragraphResolver({ node: component })}
            </React.Fragment>
          )
        })}
      {relationships?.field_related_case_studies && (
        <RelatedCaseStudies
          caseStudies={relationships.field_related_case_studies}
        />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ($id: String) {
    nodeCaseStudy(id: { eq: $id }) {
      id
      title
      path {
        alias
      }
      field_hero_text {
        processed
        value
      }
      field_headline
      field_eyebrow
      field_plain_text
      field_year
      field_link {
        title
        url
      }
      ...Metatag
      relationships {
        field_related_case_studies {
          field_headline
          field_year
          path {
            alias
          }
          relationships {
            field_categories {
              id
              name
            }
          }
        }
        field_scope_of_work {
          id
          name
        }
        field_media {
          field_image {
            alt
          }
          relationships {
            field_image {
              publicUrl
            }
          }
        }
        field_components {
          __typename
          ...ParagraphText
          ...ParagraphImage
          ...Paragraph3ColumnText
        }
      }
    }
  }
`

export default CaseStudyTemplate

export const Head = ({ data: { nodeCaseStudy } }: HeadProps<DataProps>) => {
  return (
    <>
      <html lang={nodeCaseStudy.langcode} />
      {nodeCaseStudy?.metatag ? (
        <Seo metatag={nodeCaseStudy.metatag} />
      ) : (
        <title>{nodeCaseStudy.title}</title>
      )}
    </>
  )
}

import * as React from "react"
import * as styles from "./related-case-studies.module.scss"
import Container from "react-bootstrap/Container"
import Heading from "../heading/heading"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Link from "../link/link"
import {NodeCaseStudyProps} from "../../pages/{nodeCaseStudy.path__alias}"

const RelatedCaseStudies = ({caseStudies}: { caseStudies: NodeCaseStudyProps[] }) => {
  return (
    <Container fluid className={styles.relatedCaseStudyWrapper}>
      {caseStudies?.map((caseStudy) => {
        const categories = caseStudy.relationships?.field_categories
        return (
          <Row className={`${styles.relatedCaseStudyItem}`} key={caseStudy.id}>
            <Link to={caseStudy.path.alias} className={`${styles.relatedCaseStudyLink} p-2 p-md-6`}>
              <Col sm={12} md={7} className={`${styles.relatedCaseStudyHeader}`}>
                <Heading
                  level={3}
                  title={caseStudy.title}
                  headingStyle="relatedCaseStudyHeading"
                />
              </Col>
              {categories &&
                <Col xs={12} className={`${styles.relatedCaseStudyCategories}`}>
                  {categories.map((category, categoryIndex) => {
                    return (
                      <span key={category.id}>
                      {category.name} {categoryIndex !== categories.length - 1 && " - "}
                      </span>
                    )
                  })}
                </Col>
              }
            </Link>
          </Row>
        )
      })}
    </Container>
  )
}

export default RelatedCaseStudies
